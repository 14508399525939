import React from "react"
import Awards from "./awards/Awards"
import Featured from "./featured/Featured"
import Hero from "./hero/Hero"
import Location from "./location/Location"
import SocialIcons from "../common/SocialIcons/index"
import Galary from "./Galary/galary"

const Home = () => {
  return (
    <>
    <SocialIcons/>
      <Hero />

      <Featured />
      
      <Location />
     <Galary/>
      
    </>
  )
}

export default Home
