import React from "react";
import Lottie from "lottie-react";
import Heading from "../../common/Heading";
import callAnimation from "../../../assets/namaste.json"; // Adjust path as needed
import "./hero.css";

const Hero = () => {
  return (
    <>
      <section className="hero">
        <div className="container animation-layout">
          {/* Lottie Animation as an Image */}
          <Lottie
            animationData={callAnimation}
            play
            loop
            style={{ width: 150, height: 150 }}
            className="animation-image"
          />

          {/* Heading */}
          <div className="title">
          <h99>ආයුබෝවන්</h99>
      <h3>Welcome to Your Perfect Getaway</h3></div>
        </div>

     
      </section>
    </>
  );
};

export default Hero;
