import React, { useState } from 'react';
import axios from 'axios';
import './index.css';

const Register = () => {
  const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      password: '',
  });

  const handleInputChange = (e) => {
      const { id, value } = e.target;
      setFormData((prevData) => ({
          ...prevData,
          [id]: value,
      }));
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          const response = await axios.post('http://localhost:5000/api/auth/register', formData);
          alert(response.data.message || 'Registration successful!');
      } catch (error) {
          alert(error.response?.data?.message || 'Registration failed!');
      }
  };

  return (
      <form onSubmit={handleSubmit}>
          <h2>Register</h2>
          <input type="text" id="firstName" placeholder="First Name" onChange={handleInputChange} required />
          <input type="text" id="lastName" placeholder="Last Name" onChange={handleInputChange} required />
          <input type="text" id="username" placeholder="Username" onChange={handleInputChange} required />
          <input type="email" id="email" placeholder="Email" onChange={handleInputChange} required />
          <input type="password" id="password" placeholder="Password" onChange={handleInputChange} required />
          <button type="submit">Register</button>
      </form>
  );
};


export default Register;
