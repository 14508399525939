import React from 'react';
import Lottie from 'lottie-react';
import emailAnimation from '../../../assets/gmail.json';
import callAnimation from '../../../assets/viber.json';
import linkedinAnimation from '../../../assets/location.json';

const SocialIcons = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        right: '10px',
        transform: 'translateY(-50%)',
        zIndex: 1000,
      }}
    >
      {/* Email Icon */}
      <div
        style={{
          marginBottom: '5px', // Reduced margin
          backgroundColor: '#27ae60',
          padding: '5px', // Reduced padding
          borderRadius: '5px', // Reduced border radius
        }}
      >
        <a id="s1" href=".com" target="_blank" rel="noopener noreferrer">
          <Lottie animationData={emailAnimation} style={{ width: 40, height: 40 }} />
        </a>
      </div>

      {/* Call Icon */}
      <div
        style={{
          marginBottom: '5px', // Reduced margin
          backgroundColor: '#27ae60',
          padding: '5px', // Reduced padding
          borderRadius: '5px', // Reduced border radius
        }}
      >
        <a id="s1" href="tel:+94762991525" target="_blank" rel="noopener noreferrer">
          <Lottie animationData={callAnimation} style={{ width: 40, height: 40 }} />
        </a>
      </div>

      {/* Location Icon */}
      <div
        style={{
          marginBottom: '5px', // Reduced margin
          backgroundColor: '#27ae60',
          padding: '5px', // Reduced padding
          borderRadius: '5px', // Reduced border radius
        }}
      >
        <a id="s1" href="https://maps.app.goo.gl/8KNRgs4pZnZDEpH59" target="_blank" rel="noopener noreferrer">
          <Lottie animationData={linkedinAnimation} style={{ width: 40, height: 40 }} />
        </a>
      </div>
    </div>
  );
};

export default SocialIcons;
