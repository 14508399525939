export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "services",
    path: "/services",
  },
  {
    text: "blog",
    path: "/blog",
  },
  {
    text: "pricing",
    path: "/pricing",
  },
  {
    text: "contact",
    path: "/contact",
  },
]
export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Spacious living areas",
    total: "Comfort Features ",
  },
  {
    cover: "../images/hero/h2.png",
    name: "Fully equipped kitchen",
    total: "Comfort Features",
  },
  {
    cover: "../images/hero/h3.png",
    name: "Onsite parking",
    total: "Convenience",
  },
  {
    cover: "../images/hero/h4.png",
    name: "Secure property with  cameras",
    total: "Safety and Security",
  },
  {
    cover: "../images/hero/h6.png",
    name: "A beautiful big garden perfect for walks and outdoor relaxation",
    total: "Relaxation",
  },
]
export const list = [
  
  {
    id: 4,
    cover: "../images/list/p-4.jpg",
    name: "Kottawa natural pool",
    location: "kottawa",
    
  },
  {
    id: 5,
    cover: "../images/list/p-5.jpg",
    name: "GAlle light house",
    location: "Galle Fort,Galle",
    
  },
  {
    id: 6,
    cover: "../images/list/p-6.jpg",
    name: "THalpe beach",
    location: "Thalpe Habaraduwa",
   
  },
]
export const awards = [
  {
    icon: <i class='fa-solid fa-trophy'></i>,
    num: "32 M	",
    name: "Blue Burmin Award",
  },
  {
    icon: <i class='fa-solid fa-briefcase'></i>,
    num: "43 M",
    name: "Mimo X11 Award",
  },
  {
    icon: <i class='fa-solid fa-lightbulb'></i>,
    num: "51 M",
    name: "Australian UGC Award",
  },
  {
    icon: <i class='fa-solid fa-heart'></i>,
    num: "42 M",
    name: "IITCA Green Award",
  },
]
export const galary = [
  {
    id: 1,
    name: "Koggala Lake, Sri Lanka",
    Villas: "850m",
    cover: "../images/list/1.png",
  },
  {
    id: 2,
    name: "koggala beach, Sri Lanka",
    Villas: "1.6km",
    cover: "../images/list/2.png",
  },
  {
    id: 3,
    name: "coconut tree hill,mirissa",
    Villas: "23km",
    cover: "../images/list/3.png",
  },
  {
    id: 4,
    name: "Kottawa natural pool",
    Villas: "19km",
    cover: "../images/list/4.png",
  },
  {
    id: 5,
    name: "Galle light house",
    Villas: "15 km",
    cover: "../images/list/5.png",
  },
  {
    id: 6,
    name: "Thalpe beach",
    Villas: "3.7km",
    cover: "../images/list/6.png",
  },
  {
    id: 6,
    name: "Thalpe beach",
    Villas: "3.7km",
    cover: "../images/list/7.png",
  },
  {
    id: 6,
    name: "Thalpe beach",
    Villas: "3.7km",
    cover: "../images/list/8.png",
  },
  {
    id: 6,
    name: "Thalpe beach",
    Villas: "3.7km",
    cover: "../images/list/9.png",
  },
  {
    id: 6,
    name: "Thalpe beach",
    Villas: "3.7km",
    cover: "../images/list/10.png",
  },
  {
    id: 6,
    name: "Thalpe beach",
    Villas: "3.7km",
    cover: "../images/list/11.png",
  },
  {
    id: 6,
    name: "Thalpe beach",
    Villas: "3.7km",
    cover: "../images/list/12.png",
  },
  {
    id: 6,
    name: "Thalpe beach",
    Villas: "3.7km",
    cover: "../images/list/13.png",
  },
  {
    id: 6,
    name: "Thalpe beach",
    Villas: "3.7km",
    cover: "../images/list/14.png",
  },
  {
    id: 6,
    name: "Thalpe beach",
    Villas: "3.7km",
    cover: "../images/list/15.png",
  },
  {
    id: 6,
    name: "Thalpe beach",
    Villas: "3.7km",
    cover: "../images/list/16.png",
  },
  {
    id: 6,
    name: "Thalpe beach",
    Villas: "3.7km",
    cover: "../images/list/17.png",
  },

]
export const location = [
  {
    id: 1,
    name: "Koggala Lake, Sri Lanka",
    Villas: "850m",
    cover: "../images/list/p-1.jpg",
  },
  {
    id: 2,
    name: "koggala beach, Sri Lanka",
    Villas: "1.6km",
    cover: "../images/list/p-2.jpg",
  },
  {
    id: 3,
    name: "coconut tree hill,mirissa",
    Villas: "23km",
    cover: "../images/list/p-7.jpg",
  },
  {
    id: 4,
    name: "Kottawa natural pool",
    Villas: "19km",
    cover: "../images/list/p-4.jpg",
  },
  {
    id: 5,
    name: "Galle light house",
    Villas: "15 km",
    cover: "../images/list/p-5.jpg",
  },
  {
    id: 6,
    name: "Thalpe beach",
    Villas: "3.7km",
    cover: "../images/list/p-6.jpg",
  },
]
export const team = [
  {
    list: "50",
    cover: "../images/customer/team-1.jpg",
    address: "Liverpool, Canada",
    name: "Sargam S. Singh",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "70",
    cover: "../images/customer/team-2.jpg",
    address: "Montreal, Canada",
    name: "Harijeet M. Siller",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "80",
    cover: "../images/customer/team-3.jpg",
    address: "Denever, USA",
    name: "Anna K. Young",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "51",
    cover: "../images/customer/team-4.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "42",
    cover: "../images/customer/team-5.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "38",
    cover: "../images/customer/team-5.jpg",
    address: "Montreal, USA",
    name: "Adam K. Jollio",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
]
export const price = [
  {
    plan: "Basic",
    price: "29",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "120GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "5GB Cloud Storage",
      },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Personal Help Support" },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Enterprise SLA" },
    ],
  },
  {
    best: "Best Value",
    plan: "Standard",
    price: "49",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "150GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "10GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        change: "color",
        icon: <i class='fa-solid fa-x'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Platinum",
    price: "79",
    ptext: "2 user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
]
export const footer = [
  {
    title: "LAYOUTS",
    text: [{ list: "Home Page" }, { list: "About Page" }, { list: "Service Page" }, { list: "Property Page" }, { list: "Contact Page" }, { list: "Single Blog" }],
  },
  {
    title: "ALL SECTIONS",
    text: [{ list: "Headers" }, { list: "Features" }, { list: "Attractive" }, { list: "Testimonials" }, { list: "Videos" }, { list: "Footers" }],
  },
  {
    title: "COMPANY",
    text: [{ list: "About" }, { list: "Blog" }, { list: "Pricing" }, { list: "Affiliate" }, { list: "Login" }, { list: "Changelog" }],
  },
]
