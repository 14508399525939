import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ForgotPasswordModal from '../popup/index';  // Importing the modal component
import './index.css';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState('user'); // Default user type
  const [loading, setLoading] = useState(false); // Loading state
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);

      try {
          const response = await axios.post(`http://localhost:5000/api/auth/login`, {
              email,
              password,
              userType,
          });

          if (response.data) {
              alert('Login successful!');
              const { userType } = response.data;

              // Navigate based on user type
              if (userType === 'admin') {
                  navigate('/adminpanel');
              } else if (userType === 'user') {
                  navigate('/User');
              } else {
                  alert('Unexpected user type!');
              }
          }
      } catch (error) {
          alert(error.response?.data?.message || 'Login failed!');
      } finally {
          setLoading(false);
      }
  };

  const handleForgotPasswordClick = () => {
    setIsModalOpen(true);  // Open the modal when the button is clicked
  };

  return (
      <div>
          <form onSubmit={handleSubmit}>
              <h2>Login</h2>
              <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
              />
              <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
              />
              <select value={userType} onChange={(e) => setUserType(e.target.value)}>
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
              </select>
              <button type="submit" disabled={loading}>
                  {loading ? 'Logging in...' : 'Login'}
              </button>
              <button type="button" onClick={handleForgotPasswordClick}>Forgot Password</button>
          </form>

          {/* Forgot Password Modal */}
          <ForgotPasswordModal 
            isOpen={isModalOpen} 
            onClose={() => setIsModalOpen(false)}  // Close modal function
          />
      </div>
  );
};

export default Login;
