import React from "react"
import Heading from "../../common/Heading"
import { galary } from "../../data/Data"
import "./style.css"

const Galary = () => {
  return (
    <>
      <section className='location padding'>
        <div className='container'>
          <Heading title='villa gallery' subtitle='gallery' />

          <div className='content grid3 mtop'>
            {galary.map((item, index) => (
              <div className='box' key={index}>
                <img src={item.cover} alt='' />
              
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Galary
