import React from "react"
import Back from "../common/Back"
import Heading from "../common/Heading"
import img from "../images/about.jpg"
import "./about.css"
import sri from "../../assets/sri (1).png"

const About = () => {
  return (
    <>
      <section className='about'>
        <Back name='About Us' title='About Us - Who We Are?' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
          <Heading title="Villa Shade" subtitle="Experience Tranquility in Our Villa" />

<p>Welcome to Villa Shade, where comfort meets nature. Nestled in the heart of Sri Lanka, our villa offers a perfect blend of simplicity and serenity. Whether you're seeking a peaceful retreat or an escape from the bustle of everyday life, our villa is the ideal destination.</p>

<p>Despite its modest size, our villa is known for its warm hospitality. From the moment you arrive, you'll be embraced by the charm of Sri Lankan culture and the breathtaking beauty of our surroundings. Wake up to the sound of birdsong, enjoy lush greenery, and immerse yourself in the calming atmosphere of this tranquil haven.</p>

<p>At Villa Shade, we pride ourselves on providing personalized service and ensuring every guest feels at home. Discover the magic of Sri Lanka’s natural beauty and let us make your stay truly memorable.</p>

            <button className='btn2'>More About Us</button>
          </div>
          <div className='right row'>
            <img src={sri} alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export default About
