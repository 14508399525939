import React, { useState } from "react";
import validator from "validator"; // Install this using `npm install validator`
import "./footer.css";

const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [country, setCountry] = useState("");
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const sanitizeInput = (input) => {
    return validator.escape(input.trim()); // Removes any potentially harmful characters
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    // Basic input validation
    if (!name || !email || !contactNumber || !country || !question) {
      setError("All fields are required.");
      setLoading(false);
      return;
    }

    if (!validator.isEmail(email)) {
      setError("Invalid email format.");
      setLoading(false);
      return;
    }

    if (!validator.isMobilePhone(contactNumber, "any")) {
      setError("Invalid contact number.");
      setLoading(false);
      return;
    }

    // Sanitize inputs
    const sanitizedData = {
      name: sanitizeInput(name),
      email: sanitizeInput(email),
      contactNumber: sanitizeInput(contactNumber),
      country: sanitizeInput(country),
      question: sanitizeInput(question),
    };

    try {
      const response = await fetch("https://message-9b0288dad5f5.herokuapp.com/api/questions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sanitizedData),
      });

      const result = await response.json();

      if (response.ok) {
        alert(result.message || "Your question has been sent successfully!");
        setName("");
        setEmail("");
        setContactNumber("");
        setCountry("");
        setQuestion("");
      } else {
        setError(result.message || "Failed to send your question. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <footer>
      <div className="container">
        <div className="box">
          <div className="logo">
            <h2>Have Questions About Our Villa?</h2>
            <p>Feel free to ask anything! We'll get back to you promptly.</p>

            {/* Question Form */}
            <form className="input flex" onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                aria-label="Name"
              />
              <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                aria-label="Email Address"
              />
              <input
                type="tel"
                placeholder="Contact Number"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                required
                aria-label="Contact Number"
              />
              <input
                type="text"
                placeholder="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
                aria-label="Country"
              />
              <textarea
                placeholder="Your Question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                required
                aria-label="Question"
                style={{
                  resize: "none",
                  padding: "10px",
                  marginTop: "10px",
                  width: "100%",
                  height: "100px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              />
              {error && <p style={{ color: "red" }}>{error}</p>}
              <button
                type="submit"
                style={{ marginTop: "10px" }}
                disabled={loading}
              >
                {loading ? "Sending..." : "Send"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
