import React from "react";
import Lottie from "lottie-react";
import livingAreas from "../../../assets/building.json"; // Replace with the correct paths
import kitchen from "../../../assets/cooking.json";
import parking from "../../../assets/parking.json";
import security from "../../../assets/bitcoin-access.json";
import garden from "../../../assets/plant.json";

const FeaturedCard = () => {
  const featured = [
    {
      icon: livingAreas,
      name: "Spacious living areas",
      total: "Comfort Features",
    },
    {
      icon: kitchen,
      name: "Fully equipped kitchen",
      total: "Comfort Features",
    },
    {
      icon: parking,
      name: "Onsite parking",
      total: "Convenience",
    },
    {
      icon: security,
      name: "Secure property with cameras",
      total: "Safety and Security",
    },
    {
      icon: garden,
      name: "A beautiful big garden perfect for walks and outdoor relaxation",
      total: "Relaxation",
    },
  ];

  return (
    <>
      <div className="content grid5 mtop">
        {featured.map((items, index) => (
          <div className="box" key={index}><div className="box1">
            <Lottie 
              animationData={items.icon} 
              style={{ width: 50, height: 50 }} // Adjust size as needed
            /></div>
            <h4>{items.name}</h4>
            <label>{items.total}</label>
          </div>
        ))}
      </div>
    </>
  );
};

export default FeaturedCard;
